/* eslint-disable */
// import { storeAsync } from '@/app-module';
// const store = storeAsync();
import { useStore } from '@/store'
import router from '@/router'

export default {
  async beforeRouteEnter(to, from, next) {
    // debugger
    if (!to.meta || !to.meta.unauth) {
      next();
      return;
    }
    const store = useStore()

    let currentUser = localStorage.getItem('currentUser');
    if (currentUser) {
      await store.dispatch('auth/doWaitUntilInit');
    }
    
    if (store.getters['auth/signedIn']) {
      // router.push(`/`);
      next({ path: '/' });
      return;
    }

    next();
  },
};
