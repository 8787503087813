/* eslint-disable */
// import { storeAsync } from '@/app-module';
// const store = storeAsync();
import { useStore } from '@/store'

export default {
  async beforeRouteEnter(to, from, next) {
    // debugger
    if (!to.meta || !to.meta.notEmptyPermissions) {
      next();
      return;
    }

    const store = useStore()

    await store.dispatch('auth/doWaitUntilInit');

    if (
      store.getters['auth/signedIn'] &&
      store.getters['auth/role'] === 'admin'
      // store.getters['auth/roles'].length
    ) {
      next('/');
    } else {
      next();
    }
  },
};
